<template>
  <button
    class="btn btn-secondary btn-sm"
    :title="$t(`uploadImage.manageImages`)"
    v-on:click="show = !show"
  >
    <BaseIcon name="gallery" color="var(--black)" />
  </button>
  <ImageEditor
    v-model:show="show"
    :id="id"
    :is-variant="main !== null && main !== undefined"
    :type="row?.imageType"
    v-on:changeImage="changeImage"
    v-on:update="update"
  />
</template>

<script>
import { defineAsyncComponent } from "vue";
import ImageEditor from "../../inputs/ImageEditor";
const BaseIcon = defineAsyncComponent(() => import("../../icons/BaseIcon.vue"));
export default {
  name: "UploadImage",
  components: { BaseIcon, ImageEditor },
  props: {
    id: Number,
    main: Number,
    row: Object,
    modelValue: Object,
  },
  data() {
    return {
      value: this.modelValue,
      show: false,
    };
  },
  watch: {
    value() {
      this.$emit("update:modelValue", this.value);
      this.$emit("change");
    },
    modelValue() {
      this.value = this.modelValue;
    },
  },
  emits: ["update:modelValue", "change", "changeImage"],
  methods: {
    changeImage(image) {
      this.$emit("changeImage", image);
    },
    update(image) {
      console.log(1);
      this.$emit("update", image);
    },
  },
};
</script>
